import React from "react";
import { useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { FaLocationArrow, FaTimes } from "react-icons/fa";
import { useRef } from "react";

const center = {
  lat: 6.4307739,
  lng: 3.4301063,
};

const Location = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ["places"],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ null);
  const [directions, setDirections] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [coordinates, setCoordinates] = useState(null);

  const originRef = useRef();

  const destinationRef = useRef();

  if (!isLoaded) {
    return "loading....";
  }

  async function calculateRoute() {
    if (originRef.current.value === "" || destinationRef.current.value === "") {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirections(results);
    console.log(results)
    console.log(results.routes[0].legs[0].start_location.lat())
    console.log(results.routes[0].legs[0].start_location.lng())
    const lat = results.routes[0].legs[0].start_location.lat()
    const lng = results.routes[0].legs[0].start_location.lng()
    setCoordinates(`${lat}, ${lng}`);
    console.log(`${lat}, ${lng}`)
    console.log(coordinates, 'coordinates')
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  function clearRoute() {
    setDirections(null);
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    destinationRef.current.value = "";
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="map" style={{ height: "100vh", width: "100vw" }}>
            <div className="col-md-12 d-flex justify-content-center">
              <div className="search py-3">
                <div className="card" style={{background: '#00000080'}}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <Autocomplete>
                          <input
                            className="form-control shadow-none mb-1"
                            type="text"
                            placeholder="origin"
                            ref={originRef}
                          />
                        </Autocomplete>
                      </div>
                      <div className="col-md-6">
                        <Autocomplete>
                          <input
                            className="form-control shadow-none mb-1"
                            type="text"
                            placeholder="destination"
                            ref={destinationRef}
                          />
                        </Autocomplete>
                      </div>
                      <div className="col">
                       <span className="text-white">clear <FaTimes onClick={clearRoute} /></span>
                      </div>
                      <div className="col">
                        {distance}
                      </div>
                      <div className="col">
                        {duration}
                      </div>
                    </div>

                    <button
                      className="submit-btn float-end"
                      type="submit"
                      onClick={calculateRoute}
                    >
                      Search
                    </button>
                    <div className="">
                      <FaLocationArrow className="text-white" onClick={() => map.panTo(center)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              onLoad={(map) => setMap(map)}
            >
              <Marker position={center} />
              {directions && <DirectionsRenderer directions={directions}/>}
            </GoogleMap>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
