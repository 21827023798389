import React from "react";
import Sidebar from "./Sidebar";

const User = () => {
  return (
    <>
      <Sidebar />
      <div className="container my-5">
        <div className="row d-flex justify-content-center">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h4>User Page</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
