import React from "react";

const Verify = () => {
  return (
    <div className="container">
      <div
        className="row d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="col-md-6">
          <div className="card">
            <div className="card-body p-5">
              <h4 className="text-center text-green">Identity Verification</h4>
              <p>
                Your identity verification submission is currently awaiting
                verification. Kindly check back later or contact support
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
