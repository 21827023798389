import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/logo.png";
import store from "../assets/images/store.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");
  const [disableButton, setDisbaleButton] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [showEye, setShowEye] = useState(false);
  const [changeType, setChangeType] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisbaleButton(true);
    setButtonLoad(true);
    setRole("user");
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ email, password, role }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/login`, options)
      .then((data) => data.json())
      .then((response) => {
        setDisbaleButton(false);
        setButtonLoad(false);
        toast(response.message);
        localStorage.setItem("authUser", response.data.token);
        localStorage.setItem("uuid", response.data.user.uuid);
        console.log(response);
        const url = window.location.origin;
        if (response.data.user.identity_verification_status === "verified") {
          window.location = `${url}/dashboard`;
        } else if (
          response.data.user.identity_verification_status === "pending"
        ) {
          window.location = `${url}/verify`;
        } else {
          window.location = `${url}/submit-identification`;
        }
      })
      .catch((error) => {
        console.log(error.message);
        // toast(error.message)
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };
  return (
    <>
      <nav className="navbar bg-transparent">
        <div className="container-fluid">
          <span className="navbar-brand mb-0 h1 fw-bold">
            <Link to="/">
              <img src={logo} alt="logo" className="img-fluid" width={30} />
            </Link>
          </span>
        </div>
      </nav>
      <div className="container">
        <ToastContainer />
        <div className="row flex-row-reverse" style={{ height: "100vh" }}>
          <div className="col-md-5 mt-5">
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <img src={logo} className="img-fluid" alt="logo" width="60" />
                  <p className="mt-3">Login in with your app login details</p>
                </div>
                <div>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label htmlFor="email">Email Address</label>
                      <input
                        type="email"
                        className="form-control shadow-none"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="password">Password</label>
                      <input
                        type={changeType ? "password" : "text"}
                        className="form-control shadow-none"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <div
                        className="float-end mx-3"
                        style={{ marginTop: "-35px", cursor: "pointer" }}
                        onClick={() => {
                          setShowEye(!showEye);
                          setChangeType(!changeType);
                        }}
                      >
                        {showEye ? (
                          <FaEyeSlash className="text-green" />
                        ) : (
                          <FaEye className="text-green" />
                        )}
                      </div>
                    </div>
                    <div className="my-5">
                      <button
                        className="login-btn"
                        type="submit"
                        disabled={disableButton}
                      >
                        {buttonLoad ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <div
                              className="spinner-border text-white"
                              role="status"
                              style={{
                                width: "25px",
                                height: "25px",
                                fontSize: "10px",
                              }}
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 mt-5 store">
            <img
              src={store}
              alt="dilivva-store"
              className="img-fluid"
              width={500}
            />
            <p className="fw-bold mt-4">
              If you don't have the Dilivva App, you can download it on
            </p>
            <div className="apps">
              <a href="https://play.google.com/store/apps/details?id=com.nqb8.dilivvasender">
                <img
                  src="https://dilivva.com/static/media/google-play-app-store.d21ab4597641d26a1a62.png"
                  className="img-fluid m-2 app-img"
                  width="150"
                  alt="google-play"
                />
              </a>
              <a href="https://apps.apple.com/us/app/dilivva-sender/id1662208767">
                <img
                  src="https://dilivva.com/static/media/app-store.4bc811d887010296ae6b.png"
                  className="img-fluid m-2 app-img"
                  width="150"
                  alt="app-store"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
