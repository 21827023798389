import React, { useEffect, useState } from "react";
import { FaUpload } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { accessToken, uuid } from "../../helpers/accessToken";

const Identification = () => {
  const [number, setNumber] = useState("");
  const [type, setType] = useState("");
  const [image, setImage] = useState(null);
  const [imageId, setImageId] = useState("");
  const [disableButton, setDisbaleButton] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [token, setToken] = useState("");
  const [spinner, setSpinner] = useState(false);
  // const [errors, setErrors] = useState('')

  // Fetch access token
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/fetch-access-token`, options)
      .then((data) => data.json())
      .then((response) => {
        setToken(response.data.access_token);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  console.log(token);

  // Upload image to google drive
  const uploadImage = (image) => {
    setSpinner(true);
    setDisbaleButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response.id);
          setImageId(response.id);
          setSpinner(false);
          setDisbaleButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoad(true)
    setDisbaleButton(true)
    console.log(imageId, "image-id");
    console.log("button clicked");
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({ number, type, image: imageId }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/users/${uuid}/verify-identity`, options)
      .then((data) => data.json())
      .then((response) => {
        setButtonLoad(false);
        toast(response.message);
        setTimeout(() => {
          const url = window.location.origin;
          window.location = `${url}/verify`;
        }, 5000);
      })
      .catch((error) => {
        console.log(error.message);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };

  return (
    <>
      <div className="container">
        <ToastContainer />
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="text-green">Submit Identification</h5>
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12 my-4">
                    <label htmlFor="identification">Select Identification</label>
                    <select
                      className="form-select form-control shadow-none"
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      required
                    >
                      <option defaultValue>Select Type</option>
                      <option value="Voters Card">Voters Card</option>
                      <option value="International Passport ">
                        International Passport
                      </option>
                      <option value="National ID CARD">National ID Card</option>
                      <option value="Drivers License">Drivers License</option>
                    </select>
                  </div>
                  <div className="col-md-12 my-4">
                    <label htmlFor="id-number">ID Number</label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      placeholder="Enter ID Number"
                      value={number}
                      onChange={(e) => {
                        setNumber(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="col-md-12 my-4">
                    <small className="text-green">Upload ID</small>
                    <input
                      type="file"
                      className="form-control shadow-none"
                      name="file"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                        uploadImage(e.target.files[0]);
                      }}
                      required
                    />
                    {spinner && (
                      <small className="text-green"><FaUpload /> uploading...</small>
                    )}
                  </div>
                  <button
                    className="submit-btn"
                    type="submit"
                    disabled={disableButton}
                  >
                    {buttonLoad ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className="spinner-border text-white"
                          role="status"
                          style={{
                            width: "25px",
                            height: "25px",
                            fontSize: "10px",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Identification;
