import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyCkvfUXm8vUN16N--ZYKIJHaSw-k-ztypc",
  authDomain: "dilivva-test.firebaseapp.com",
  projectId: "dilivva-test",
  storageBucket: "dilivva-test.appspot.com",
  messagingSenderId: "571578040116",
  appId: "1:571578040116:web:f1685a34e6c5d34b9e6f61",
  measurementId: "G-9SWMTPMBRX",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider()
