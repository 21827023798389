import { Autocomplete } from "@react-google-maps/api";
import React, { useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ShopContext } from "../context/shopContext";
import Nav from "../utils/Nav";

let ref = window.location.pathname.split("/")[2];

const Cart = () => {
  const [state, setState] = useState("");
  const destinationRef = useRef();
  const { products, cartItems } = useContext(ShopContext);
  const sum = Object.values(cartItems).reduce((a, b) => a + b, 0);

  let carts = Object.values(products)?.filter((value) => {
    let item = cartItems[value.id];
    if (item !== 0) {
      return value;
    }
    return false;
  });

  let mappedCarts = carts.map((value) => {
    let quantity = cartItems[value.id];
    return { id: value.uuid, quantity };
  });

  let mappedProducts = carts.map((item) => {
    let quantity = cartItems[item.id];
    return { id: item.uuid, quantity, unit: item.unit };
  });

  const [delivery, setDelivery] = useState({});
  const [buttonLoad, setButtonLoad] = useState(false);
  const [disableButton, setDisbaleButton] = useState(false);
  const [address, setAddress] = useState("");
  const [booking, setBooking] = useState(true);
  const [checkRoute, setCheckRoute] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  // const [state, setState] = useState("");
  const [lga, setLga] = useState("");
  const [price, setPrice] = useState({});
  const [showPrice, setShowPrice] = useState(false);
  const [availble, setAvailable] = useState("");
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phone: "",
    unit: "",
    quantity: "",
    note: "",
  });

  // Fetch Delviery
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/bulk-deliveries/${ref}`, options)
      .then((data) => data.json())
      .then((response) => {
        setDelivery(response.data);
      })
      .catch((error) => {});
  }, []);

  async function calculateRoute() {
    setShowPrice(false);
    setCheckRoute(true)
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: delivery.pickup_address,
      destination: destinationRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setLga(
      results.request.destination.query.split(",")[
        results.request.destination.query.split.length - 1
      ]
    );

    setAddress(results?.request?.destination.query);

    const lat = results.routes[0].legs[0].end_location.lat();
    const lng = results.routes[0].legs[0].end_location.lng();

    coordinates.lat = lat;
    coordinates.lng = lng;
    console.log(lat, lng, "thisis the new one I am adding");
    checkLocation(lat, lng);
  }

  // Check if location is supported
  const checkLocation = (lat, lng) => {
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        state: state,
        coordinates: `${lat}, ${lng}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then((data) => data.json())
      .then((response) => {
        if (response.status === "success") {
          setCheckRoute(false)
          setShowPrice(true);
          calculatePrice();
        } else {
          setCheckRoute(false)
          setAvailable(response.message);
          setTimeout(() => {
            setAvailable("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // Calculate Pricing
  const calculatePrice = async () => {
    setDisbaleButton(true);
    setButtonLoad(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        products: mappedCarts,
        destination_state: state,
        destination_coordinates: `${coordinates.lat}, ${coordinates.lng}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    await fetch(`${baseURL}/api/v1/bulk-deliveries/${ref}/pricing`, options)
      .then((data) => data.json())
      .then((response) => {
        setDisbaleButton(false);
        setButtonLoad(false);
        setPrice(response.data);
        setShowPrice(true);
        setBooking(false);
      })
      .catch((error) => {
        console.log(error.message);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };

  // Create Booking

  const createBooking = (e) => {
    e.preventDefault();
    setDisbaleButton(true);
    setButtonLoad(true);
    const productUnit = delivery?.products.map((product) => product.unit);
    const unit = productUnit[0];
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        bulk_delivery_id: delivery.uuid,
        products: mappedProducts,
        name: details.name,
        email: details.email,
        phone: details.phone,
        unit,
        destination_state: state,
        destination_coordinates: `${coordinates.lat}, ${coordinates.lng}`,
        destination_local_government: lga,
        destination_address: address,
        note: details.note,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/bulk-deliveries/${ref}/book`, options)
      .then((data) => data.json())
      .then((response) => {
        window.location.href = `${response.data.authorization_url}`;
      })
      .catch((error) => {
        console.log(error.message);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };

  return (
    <>
      <Nav />
      {carts.length !== 0 ? (
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-8">
              <div className="card mb-4" style={{minHeight: "310px"}}>
                <div className="card-body">
                  <h6 className="fw-bold">CART {`(${sum})`}</h6>
                  <hr />
                  {products?.map((product) => {
                    const { id, description, images, label, unit_price } =
                      product;
                    if (cartItems[id] !== 0) {
                      return (
                        <div key={id}>
                          <div className="row">
                            <div className="col-md-7">
                              <div className="row">
                                <div className="col-4">
                                  <img
                                    src={images}
                                    className="img-fluid rounded"
                                    width={150}
                                    alt=""
                                  />
                                </div>
                                <div className="col-8">
                                  <h6 className="fw-bold">{label}</h6>
                                  <p>{description}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="d-flex justify-content-end">
                                <div>
                                  <h6 className="text-orange">
                                    ₦ {unit_price}
                                  </h6>
                                  {/* <button
                                    className="qty-btn"
                                    onClick={() => removeFromCart(id)}
                                  >
                                    -
                                  </button> */}
                                  <span className="mx-3">
                                    {" "}
                                    <b>Quantity: </b> {cartItems[id]}
                                  </span>
                                  {/* <button
                                    className="qty-btn"
                                    onClick={() => {
                                      addToCart(id);
                                    }}
                                  >
                                    +
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    }
                    return false;
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-body m-2">
                  <div className="row">
                    <h6 className="fw-bold">DELIVERY</h6>
                    <hr />
                    <div className="mb-4">
                      <label htmlFor="quantity">Choose your location</label>
                      <select
                        value={state}
                        className="form-control shadow-none my-4"
                        onChange={(e) => {
                          setState(e.target.value);
                          if(destinationRef.current.value) {
                            e.persist()
                            setState(e.target.value)
                            calculateRoute()
                          }
                        }}
                        required
                      >
                        <option>--select state--</option>
                        <option value="lagos">Lagos</option>
                        <option value="ondo">Ondo</option>
                        <option value="ogun">Ogun</option>
                        <option value="oyo">Oyo</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <Autocomplete onPlaceChanged={calculateRoute}>
                        <input
                          className="form-control shadow-none mb-1"
                          type="text"
                          placeholder="Enter your address"
                          ref={destinationRef}
                        />
                      </Autocomplete>
                    </div>
                    {checkRoute && (
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className="spinner-border text-green"
                          role="status"
                          style={{
                            width: "25px",
                            height: "25px",
                            fontSize: "10px",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                    <small className="text-danger">{availble}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showPrice && (
            <>
              {booking ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="spinner-border text-green" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="row reverse">
                  <div className="col-md-8 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <h6 className="fw-bold">CREATE BOOKING</h6>
                        <hr />
                        <form onSubmit={createBooking}>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label htmlFor="name">Full Name</label>
                              <input
                                type="text"
                                className="form-control shadow-none"
                                placeholder="Enter name"
                                value={details.name}
                                onChange={(e) =>
                                  setDetails({
                                    ...details,
                                    name: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>
                            <div className="col-md-12 mb-3">
                              <label htmlFor="email">Email Address</label>
                              <input
                                type="text"
                                className="form-control shadow-none"
                                placeholder="Enter Email Address"
                                value={details.email}
                                onChange={(e) =>
                                  setDetails({
                                    ...details,
                                    email: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>
                            <div className="col-md-12 mb-3">
                              <label htmlFor="phone">Phone Number</label>
                              <input
                                type="text"
                                className="form-control shadow-none"
                                placeholder="Enter Phone Number"
                                value={details.phone}
                                onChange={(e) =>
                                  setDetails({
                                    ...details,
                                    phone: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>
                            <div className="float-end mt-4 mb-5">
                              <button
                                className="buy-btn"
                                type="submit"
                                disabled={disableButton}
                              >
                                {buttonLoad ? (
                                  <div className="d-flex justify-content-center align-items-center">
                                    <div
                                      className="-border text-white"
                                      role="status"
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        fontSize: "10px",
                                      }}
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  "Create Booking"
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <h6 className="fw-bold">CART SUMMARY</h6>
                        <hr />
                        <div className="row">
                          <div className="mb-3">
                            <div className="col">
                              {price?.product_prices?.map((item) => (
                                <div key={item?.product}>
                                  <small>{item?.product}</small> <br />
                                  <small className="text-orange">
                                    ₦ {item?.price}
                                  </small>{" "}
                                  <br />
                                  <small className="mb-4">
                                    Qty: {item?.quantity}
                                  </small>
                                  <hr />
                                </div>
                              ))}
                              <p>
                                <strong>Product Price:</strong>{" "}
                                <span className="float-end fw-bold">
                                  ₦ {price?.total_product_prices}
                                </span>
                              </p>
                              <p>
                                <b>Delivery Price:</b>{" "}
                                <span className="float-end fw-bold">
                                  ₦ {price?.delivery_price}
                                </span>
                              </p>
                              <hr />
                              <b>Total:</b>{" "}
                              <h5 className="float-end text-orange fw-bold">
                                ₦ {price?.total}
                              </h5>
                              <hr />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="container">
          <div className="row my-5">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h6 className="fw-bold">EMPTY CART</h6>
                  <hr />
                  <p>You have no item in your cart</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
