import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { accessToken, uuid } from "../../helpers/accessToken";
import Sidebar from "../dashboard/Sidebar";

const Withdrawal = () => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [disableButton, setDisbaleButton] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({});

  // Fetch User Details
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    const uuid = localStorage.getItem("uuid");
    fetch(`${baseURL}/api/v1/users/${uuid}`, options)
      .then((data) => data.json())
      .then((response) => {
        if (response.message === "Unauthenticated.") {
          window.location = `/`;
        }
        setUser(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, []);

  // Fetch Account details
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/users/${uuid}/bank-account`, options)
      .then((data) => data.json())
      .then((response) => {
        console.log(response.data);
        setDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, []);

  const withdraw = async () => {
    if (window.confirm("Are you sure you want to withdraw all your money?")) {
      setDisbaleButton(true);
      setButtonLoad(true);
      const options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }),
      };
      const baseURL = process.env.REACT_APP_BASEURL;
      await fetch(`${baseURL}/api/v1/users/${uuid}/process-withdrawal`, options)
        .then((data) => data.json())
        .then((response) => {
          toast(response.message);
          console.log(response);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          setError(response.errors.amount[0]);
          console.log(response.data);
          if (response.status === "success") {
            window.location.href = `/dashboard`;
          }
          setDisbaleButton(false);
          setButtonLoad(false);
        })
        .catch((error) => {
          setDisbaleButton(false);
          setButtonLoad(false);
        });
    } else {
      return false;
    }
  };

  return (
    <>
      <Sidebar />
      <ToastContainer />
      <div className="container my-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10 main-card">
            {loading ? (
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="card ">
                    <div className="card-body">
                      <small className="text-orange">Your Account Number</small>
                      <div className="col-md-3 skeleton skeleton-head"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card ">
                    <div className="card-body">
                      <small className="text-orange">Account Name</small>
                      <div className="col-md-8 skeleton skeleton-head"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card ">
                    <div className="card-body">
                      <small className="text-orange">Bank Name</small>
                      <div className="col-md-3 skeleton skeleton-head"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card ">
                    <div className="card-body">
                      <small className="text-orange">BVN</small>
                      <div className="col-md-4 skeleton skeleton-head"></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row d-flex justify-content-center">
                <div className="col-md-6 mb-3">
                  <div className="card ">
                    <div className="card-body">
                      <div className="col-md-8 mb-5">
                        <div className="wallet-card">
                          <small>Balance</small>
                          <h3 className="fw-bold">₦ {user?.wallet?.balance}</h3>
                        </div>
                      </div>
                      {details.account_number ? (
                        <>
                          <small className="text-orange">Account Name</small>
                          <h6 className="text-green">{details.account_name}</h6>
                          <small className="text-orange">Account Number</small>
                          <h6 className="text-green">
                            {details.account_number}
                          </h6>
                          <small className="text-orange">Bank Name</small>
                          <h6 className="text-green">{details.bank_name}</h6>
                          <small className="text-orange">BVN</small>
                          <h6 className="text-green">{details.bvn}</h6>
                          <div className="float-end">
                            <button
                              className="submit-btn"
                              type="submit"
                              disabled={disableButton}
                              onClick={() => withdraw()}
                            >
                              {buttonLoad ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <div
                                    className="spinner-border text-white"
                                    role="status"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      fontSize: "10px",
                                    }}
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                "Withdraw"
                              )}
                            </button>
                          </div>
                          <small className="text-danger">
                            {error ? error : null}
                          </small>
                        </>
                      ) : (
                        <Link to="/add-account" className="btn btn-primary">Add account</Link>
                      )}{" "}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdrawal;
