import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { accessToken, uuid } from "../../helpers/accessToken";
import Sidebar from "../dashboard/Sidebar";

const FetchDelivery = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  // Fetch Delviery
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/users/${uuid}/bulk-deliveries/?sort=desc`, options)
      .then((data) => data.json())
      .then((response) => {
        setDeliveries(response.data.bulk_deliveries);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="container main-card my-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                <h4 className="text-green">Store Details</h4>
                {loading ? (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 py-4 mb-3 skeleton skeleton-search"></div>
                      <div className="col-md-12 py-4 mb-3 skeleton skeleton-search"></div>
                      <div className="col-md-12 py-4 mb-3 skeleton skeleton-search"></div>
                      <div className="col-md-12 py-4 mb-3 skeleton skeleton-search"></div>
                    </div>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Reference</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deliveries?.map((delivery, index) => {
                          const {
                            id,
                            name,
                            description,
                            reference,
                            status,
                            uuid,
                          } = delivery;
                          return (
                            <tr
                              key={id}
                              className="sort"
                              onClick={() =>
                                navigate("/bulk-deliveries/" + uuid)
                              }
                            >
                              <td>{index + 1}</td>
                              <td>{name}</td>
                              <td>{description}</td>
                              <td>{reference}</td>
                              <td
                                style={{
                                  color:
                                    status === "active"
                                      ? "#29CC97"
                                      : status === "expired"
                                      ? "#DC1515"
                                      : "#FEC400",
                                }}
                              >
                                {status}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FetchDelivery;
