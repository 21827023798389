import React, { useEffect, useState } from "react";
import { reference } from "../components/booking/Booking";

export const ShopContext = React.createContext({});

export const ShopProvider = ({ children }) => {
  const [products, setProducts] = useState({});

  // Fetch Delviery
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/bulk-deliveries/${reference}`, options)
      .then((data) => data.json())
      .then((response) => {
        setProducts(response.data.products)
        let productResponse = response.data.products
        let cart = {};
        for (let i = 0; i < productResponse.length; i++) {
          let productId = productResponse[i]?.id;
          cart[productId] = 0;
        }
        setCartItems(cart);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  

  const [cartItems, setCartItems] = useState([]);

  const addToCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
  };
  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
  };

  const contextValue = { cartItems, addToCart, removeFromCart, products };


  return (
    <ShopContext.Provider value={contextValue}>{children}</ShopContext.Provider>
  );
};
