import React from 'react'
import { useContext } from 'react'
import { FaShoppingCart } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { reference } from '../components/booking/Booking'
import { ShopContext } from '../context/shopContext'

const Nav = () => {
  const {cartItems} = useContext(ShopContext)
  const sum = Object.values(cartItems).reduce((a,b) => a+b, 0)
  return (
    <nav className="navbar navbar-expand-lg bg-dark sticky-top">
        <div className="container-fluid">
          <div className=""></div>
          <div className="d-flex justify-content-end py-3">
            <Link to={`../../cart/${reference}`}>
              <small className="cart">
                <FaShoppingCart /> Cart
              </small>
              <sup className="cart-number mx-1">
                <span>{sum}</span>
              </sup>
            </Link>
          </div>
        </div>
      </nav>
  )
}

export default Nav