import React, { useEffect, useState } from "react";
// import CurrencyInput from "react-currency-input-field";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CurrencyFormat from "react-currency-format";
import { FaCopy, FaUpload } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { accessToken, uuid } from "../../helpers/accessToken";
import Sidebar from "../dashboard/Sidebar";

// import Geocode from "react-geocode";

// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";
// import { FaMapPin } from "react-icons/fa";

// import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

// let center = {
//   lat: 6.4307739,
//   lng: 3.4301063,
// };

const DeliveryDetails = (props) => {
  const [delivery, setDelivery] = useState({});
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  const [token, setToken] = useState("");
  const [disableButton, setDisbaleButton] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [imageId, setImageId] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [size, setSize] = useState("");
  const [copied, setCopied] = useState(false);
  const [details, setDetails] = useState({
    label: "",
    description: "",
    unitPrice: "",
    unit: "",
    isFragile: "",
    note: "",
  });
  const [format, setFormat] = useState("");
  const [exportData, setExportData] = useState(false);
  // const [showProduct, setShowProduct] = useState(true);
  // const [address, setAddress] = useState("");
  // const [state, setState] = useState("");
  // const [coordinates, setCoordinates] = useState({
  //   lat: null,
  //   lng: null,
  // });
  // const [available, setAvailable] = useState();
  // const [markers, setMarkers] = useState([]);

  const location = useLocation();
  // Fetch Delviery
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/users/${uuid}${location.pathname}`, options)
      .then((data) => data.json())
      .then((response) => {
        console.log(response.data);
        setDelivery(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, [location.pathname]);

  // Fetch package size
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/sizes`, options)
      .then((data) => data.json())
      .then((response) => {
        setSizes(response.data);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, []);

  // Fetch access token
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/fetch-access-token`, options)
      .then((data) => data.json())
      .then((response) => {
        setToken(response.data.access_token);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  // Upload image to google drive
  const uploadImage = (image) => {
    setSpinner(true);
    setDisbaleButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          setImageId(response.id);
          setSpinner(false);
          setDisbaleButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisbaleButton(true);
    setButtonLoad(true);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        size_id: size,
        label: details.label,
        description: details.description,
        unit: details.unit,
        unit_price: details.unitPrice,
        is_fragile: details.isFragile,
        images: [imageId],
        note: details.note,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(
      `${baseURL}/api/v1/users/${uuid}${location.pathname}/products`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        setDisbaleButton(false);
        setButtonLoad(false);
        toast(response.message);
        if (response.status === "success") {
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };

  // Export bookings
  const exportBookings = async (e) => {
    e.preventDefault();
    console.log("bookings exported", format);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        export_format: format,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    await fetch(
      `${baseURL}/api/v1/bulk-deliveries/${delivery.uuid}/export`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        if (response.status === "success") {
          console.log(response.message);
          toast.success(response.message);
          setExportData(false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };

  // Activate Store
  const activateDelivery = async (id) => {
    console.log(id);
    if (window.confirm("Are you sure this item is ready to be delivered?")) {
      const options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }),
      };
      const baseURL = process.env.REACT_APP_BASEURL;
      await fetch(
        `${baseURL}/api/v1/users/bulk-delivery-bookings/${id}/activate`,
        options
      )
        .then((data) => data.json())
        .then((response) => {
          console.log(response.message);
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload(true);
          }, 3000);
        })
        .catch((error) => {
          console.log(error.message);
          setDisbaleButton(false);
          setButtonLoad(false);
        });
    } else {
      return false;
    }
  };

  // ----------------------------------------------------------------

  // const handleSelect = async (value) => {
  //   const results = await geocodeByAddress(value);
  //   console.log(
  //     results[0].address_components.map((address) => address.long_name)
  //   );
  //   console.log(results[0].address_components.map((address) => address.types));
  //   console.log(
  //     results[0].address_components.map((address) => address.long_name[0])
  //   );
  //   const ll = await getLatLng(results[0]);
  //   console.log(ll, "latiii");
  //   console.log(ll.lat, ll.lng);
  //   console.log(
  //     results[0].formatted_address.split(",")[
  //       results[0].formatted_address.split(",").length - 2
  //     ]
  //   );
  //   // setState(
  //   //   results[0].formatted_address.split(",")[
  //   //     results[0].formatted_address.split(",").length - 2
  //   //   ]
  //   // );
  //   setAddress(value);
  //   center = {
  //     lat: ll.lat,
  //     lng: ll.lng,
  //   };
  //   setCoordinates(`${ll.lat}, ${ll.lng}`);
  // };

  // const checkLocation = () => {
  //   console.log(state);
  //   const options = {
  //     method: "POST",
  //     headers: new Headers({
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //     }),
  //     body: JSON.stringify({
  //       state,
  //       coordinates,
  //     }),
  //   };
  //   const baseURL = process.env.REACT_APP_BASEURL;
  //   fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
  //     .then((data) => data.json())
  //     .then((response) => {
  //       if (response.status !== "success") {
  //         console.log(response.status);
  //         setAvailable(response.message);
  //       }
  //       // else {
  //       //   console.log(response.message);
  //       //   console.log(response.status);
  //       //   setAvailable(response.message);
  //       // }
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //     });
  // };

  // // handle map click
  // const handleEvent = (event) => {
  //   let latCord = `${event.latLng.lat()}`;
  //   let lngCord = `${event.latLng.lng()}`;
  //   console.log(latCord);
  //   console.log(lngCord);

  //   // Set Marker

  //   setMarkers((current) => [
  //     ...current,
  //     {
  //       lat: event.latLng.lat(),
  //       lng: event.latLng.lng(),
  //     },
  //   ]);

  //   // Geocode
  //   Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_MAP_API}`);

  //   // set response language. Defaults to english.
  //   Geocode.setLanguage("en");
  //   // Get address from latitude & longitude.
  //   Geocode.fromLatLng(`${latCord}`, `${lngCord}`).then(
  //     (response) => {
  //       const address = response.results[0].formatted_address;
  //       console.log(address);
  //       setAddress(address);
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // };
  // ----------------------------------------------------------------

  return (
    <>
      <Sidebar />

      <div className="container main-card my-5">
        <ToastContainer />
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                <h4 className="text-green">Store Details</h4>
                {loading ? (
                  <div className="container">
                    <div className="row my-2">
                      <div className="col-md-6">
                        <div className="col mb-3 skeleton skeleton-img rounded"></div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-2 skeleton skeleton-p"></div>
                        <div className="col-md-3 skeleton skeleton-head mb-3"></div>
                        <div className="col-md-4 skeleton skeleton-p"></div>
                        <div className="col-md-3 skeleton skeleton-p mb-3"></div>
                        <div className="col-md-4 skeleton skeleton-p"></div>
                        <div className="col-md-8  skeleton skeleton-p mb-3"></div>
                        <div className="col-md-5 skeleton skeleton-p"></div>
                        <div className="col-md-10 skeleton skeleton-p mb-3"></div>
                        <div className="col-md-3 skeleton skeleton-p"></div>
                        <div className="col-md-4 skeleton skeleton-p mb-2"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6 py-4">
                              <img
                                src={delivery?.image}
                                alt={delivery?.name}
                                className="img-fluid rounded"
                                width={265}
                              />
                              <div className="pt-3">
                                <small className="text-green">Link</small>

                                <div className="col-md-12 d-flex justify-content-between align-items-center pb-3">
                                  <input
                                    type="text"
                                    value={delivery?.link}
                                    className="form-control shadow-none"
                                    readOnly
                                  />
                                  <CopyToClipboard
                                    text={delivery?.link}
                                    onCopy={() => {
                                      setCopied(true);
                                      setTimeout(() => {
                                        setCopied(false);
                                      }, 1500);
                                    }}
                                  >
                                    <span
                                      style={{
                                        background: "#f7f7f7",
                                        margin: "0 10px",
                                        padding: "10px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                        color: "#444",
                                      }}
                                    >
                                      <FaCopy />
                                    </span>
                                  </CopyToClipboard>
                                  {copied && (
                                    <small className="text-green mx-1">
                                      copied!
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <small className="text-green">Name</small>
                              <h4>{delivery?.name}</h4>
                              <small className="text-green">Description</small>
                              <p>{delivery?.description}</p>
                              <small className="text-green">Refernce</small>
                              <p>{delivery?.reference}</p>
                              <small className="text-green">
                                Pickup Address
                              </small>
                              <p>{delivery?.pickup_address}</p>
                              <small className="text-green">Status</small>
                              <p
                                style={{
                                  color:
                                    delivery?.status === "active"
                                      ? "#29CC97"
                                      : delivery?.status === "expired"
                                      ? "#DC1515"
                                      : "#FEC400",
                                }}
                              >
                                {delivery?.status}
                              </p>

                              <div>
                                {/* <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  Edit
                                </button>

                                <div
                                  className="modal fade"
                                  id="exampleModal"
                                  tabIndex="-1"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h1
                                          className="modal-title fs-5"
                                          id="exampleModalLabel"
                                        >
                                          Edit Store
                                        </h1>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      <div className="modal-body">
                                        <form onSubmit={handleSubmit}>
                                          <div className="row">
                                            <img
                                              src={delivery?.images}
                                              alt={delivery?.label}
                                              className="img-fluid rounded my-3"
                                              style={{ width: "400px" }}
                                            />
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6 my-4">
                                              <label htmlFor="label">
                                                Name
                                              </label>
                                              <input
                                                type="text"
                                                value={delivery.name}
                                                onChange={(e) =>
                                                  setDelivery({
                                                    ...delivery,
                                                    label: e.target.value,
                                                  })
                                                }
                                                className="form-control shadow-none"
                                                placeholder="Label"
                                                required
                                              />
                                            </div>
                                            <div className="col-md-6 my-4">
                                              <label htmlFor="description">
                                                Description
                                              </label>
                                              <textarea
                                                value={delivery.description}
                                                onChange={(e) =>
                                                  setDelivery({
                                                    ...delivery,
                                                    description: e.target.value,
                                                  })
                                                }
                                                className="form-control shadow-none"
                                                placeholder="Description"
                                                required
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-12 my-4">
                                              <label htmlFor="pickup">
                                                Pickup Address(Address you'll be
                                                shipping the item from)
                                              </label>
                                              <PlacesAutocomplete
                                                value={delivery?.pickup_address}
                                                onChange={setAddress}
                                                onSelect={handleSelect}
                                              >
                                                {({
                                                  getInputProps,
                                                  suggestions,
                                                  getSuggestionItemProps,
                                                  loading,
                                                }) => (
                                                  <div>
                                                    <input
                                                      {...getInputProps({
                                                        placeholder:
                                                          "Enter Pickup Address",
                                                        className:
                                                          "form-control shadow-none",
                                                      })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                      {loading && (
                                                        <div>Loading...</div>
                                                      )}
                                                      {suggestions.map(
                                                        (suggestion, index) => {
                                                          const className =
                                                            suggestion.active
                                                              ? "suggestion-item--active"
                                                              : "suggestion-item";
                                                          // inline style for demonstration purpose
                                                          const style =
                                                            suggestion.active
                                                              ? {
                                                                  backgroundColor:
                                                                    "#fafafa",
                                                                  cursor:
                                                                    "pointer",
                                                                }
                                                              : {
                                                                  backgroundColor:
                                                                    "#ffffff",
                                                                  cursor:
                                                                    "pointer",
                                                                };
                                                          return (
                                                            <div
                                                              {...getSuggestionItemProps(
                                                                suggestion,
                                                                {
                                                                  className,
                                                                  style,
                                                                }
                                                              )}
                                                              key={
                                                                suggestion.index
                                                              }
                                                            >
                                                              <span
                                                                onSelect={
                                                                  checkLocation
                                                                }
                                                              >
                                                                <FaMapPin className="text-green mx-1" />{" "}
                                                                {
                                                                  suggestion.description
                                                                }
                                                              </span>
                                                              <hr />
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </PlacesAutocomplete>
                                            </div>
                                          </div>
                                          <button
                                            className="submit-btn border-0"
                                            type="submit"
                                            disabled={disableButton}
                                          >
                                            {buttonLoad ? (
                                              <div className="d-flex justify-content-center align-items-center">
                                                <div
                                                  className="spinner-border text-white"
                                                  role="status"
                                                  style={{
                                                    width: "25px",
                                                    height: "25px",
                                                    fontSize: "10px",
                                                  }}
                                                >
                                                  <span className="visually-hidden">
                                                    Loading...
                                                  </span>
                                                </div>
                                              </div>
                                            ) : (
                                              "Update"
                                            )}
                                          </button>
                                        </form>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          data-bs-dismiss="modal"
                                        >
                                          Close
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                        >
                                          Save changes
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container main-card my-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="text-green">Products</h5>

                    {delivery?.products?.length ? (
                      delivery?.products?.map((product) => {
                        const {
                          id,
                          description,
                          images,
                          is_fragile,
                          is_availalble,
                          label,
                          note,
                          reference,
                          size,
                          unit_price,
                        } = product;
                        return (
                          <div className="container" key={id}>
                            <div className="row py-2 mb-4">
                              <div className="col-md-6">
                                <img
                                  src={images}
                                  alt={description}
                                  className="img-fluid rounded"
                                  width={200}
                                />
                                <p className="mt-2 mb-0">
                                  <strong>{label}</strong>
                                </p>
                                <small>{description}</small> <br />
                                <small>{reference}</small>
                              </div>
                              <div className="col-md-6">
                                <p>
                                  {is_fragile === 0 ? "Fragile" : "Not Fragile"}
                                </p>
                                <p>₦{unit_price}</p>
                                <p>{is_availalble}</p>
                                <p>
                                  {size.name}
                                  <span
                                    className="text-green mx-1"
                                    style={{ fontSize: "11px" }}
                                  >
                                    ({size.description})
                                  </span>
                                </p>
                                <p>{note}</p>
                                <Link
                                  to={`/${uuid}/bulk-deliveries/${delivery?.uuid}/products/${product?.uuid}`}
                                  className="badge bg-warning border-0"
                                >
                                  edit
                                </Link>
                                <hr />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p>No Products have been created</p>
                    )}
                  </div>
                </div>

                <div className="container my-5">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="submit-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        {delivery?.products?.length
                          ? "Add New Product"
                          : "Create Product"}
                      </button>

                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                Create a New Product
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <form onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-md-6 my-4">
                                    <label htmlFor="label">Label</label>
                                    <input
                                      type="text"
                                      value={details?.label}
                                      onChange={(e) =>
                                        setDetails({
                                          ...details,
                                          label: e.target.value,
                                        })
                                      }
                                      className="form-control shadow-none"
                                      placeholder="Name of the product"
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 my-4">
                                    <label htmlFor="size">Size</label>
                                    <select
                                      className="form-select form-control shadow-none"
                                      value={size}
                                      onChange={(e) => {
                                        setSize(e.target.value);
                                      }}
                                      required
                                    >
                                      <option value="1">--select--</option>
                                      {sizes?.map((size) => {
                                        const { id, uuid, name, description } =
                                          size;
                                        return (
                                          <option
                                            className=""
                                            key={id}
                                            value={uuid}
                                          >
                                            {name} ({description})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-md-6 my-4">
                                    <label htmlFor="description">
                                      Description
                                    </label>
                                    <textarea
                                      value={details?.description}
                                      onChange={(e) =>
                                        setDetails({
                                          ...details,
                                          description: e.target.value,
                                        })
                                      }
                                      className="form-control shadow-none"
                                      placeholder="Description of the product"
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 my-4">
                                    <label htmlFor="unit">
                                      Unit (yards, cm, ft)
                                    </label>
                                    <input
                                      type="text"
                                      value={details?.unit}
                                      onChange={(e) => {
                                        setDetails({
                                          ...details,
                                          unit: e.target.value,
                                        });
                                      }}
                                      className="form-control shadow-none"
                                      placeholder="unit"
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 my-4">
                                    <label htmlFor="unit-price">
                                      Unit Price
                                    </label>
                                    <input
                                      type="text"
                                      value={details?.unitPrice}
                                      onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          setDetails({
                                            ...details,
                                            unitPrice: e.target.value,
                                          });
                                        }
                                      }}
                                      className="form-control shadow-none"
                                      placeholder="unit price"
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 my-4">
                                    <label htmlFor="is-fragile">Fragile</label>
                                    <select
                                      className="form-select form-control shadow-none"
                                      value={details?.isFragile}
                                      onChange={(e) =>
                                        setDetails({
                                          ...details,
                                          isFragile: e.target.value,
                                        })
                                      }
                                      required
                                    >
                                      <option value>Select Type</option>
                                      <option value="1">yes</option>
                                      <option value="0">no</option>
                                    </select>
                                  </div>
                                  <div className="col-md-6 my-4">
                                    <label htmlFor="image">
                                      Upload Product Image
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control shadow-none"
                                      name="file"
                                      accept="image/png, image/gif, image/jpeg, image/jpg"
                                      multiple
                                      required
                                      onChange={(e) => {
                                        let new_image = image;
                                        new_image.push(e.target.files[0]);
                                        setImage(new_image);
                                        uploadImage(e.target.files[0]);
                                      }}
                                    />
                                    {spinner && (
                                      <small className="text-green">
                                        <FaUpload /> uploading...
                                      </small>
                                    )}
                                  </div>
                                  <div className="col-md-6 my-4">
                                    <label htmlFor="note">Add Note</label>
                                    <textarea
                                      value={details?.note}
                                      onChange={(e) =>
                                        setDetails({
                                          ...details,
                                          note: e.target.value,
                                        })
                                      }
                                      className="form-control shadow-none"
                                      placeholder="Add a note for the product"
                                    />
                                  </div>
                                </div>
                                <div className="row"></div>
                                <button
                                  className="submit-btn"
                                  type="submit"
                                  disabled={disableButton}
                                >
                                  {buttonLoad ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                      <div
                                        className="spinner-border text-white"
                                        role="status"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                          fontSize: "10px",
                                        }}
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    "Add Product"
                                  )}
                                </button>
                              </form>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container main-card my-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                <h4>Bookings</h4>
                {loading ? (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 mb-3 skeleton skeleton-search"></div>
                      <div className="col-md-12 skeleton skeleton-search"></div>
                      <div className="col-md-12 skeleton skeleton-search"></div>
                      <div className="col-md-12 skeleton skeleton-search"></div>
                    </div>
                  </div>
                ) : (
                  <>
                    {delivery?.bookings?.length !== 0 ? (
                      <div>
                        <form onSubmit={exportBookings}>
                          <div className="col-md-2">
                            <select
                              className="form-select shadow-none"
                              value={format}
                              onChange={(e) => {
                                setFormat(e.target.value);
                                setExportData(true);
                              }}
                              required
                            >
                              <option>--export--</option>
                              <option value="pdf">pdf</option>
                              <option value="csv">csv</option>
                              <option value="excel">excel</option>
                            </select>
                            {exportData && (
                              <button type="submit" className="submit-btn mt-3">
                                Export
                              </button>
                            )}
                          </div>
                        </form>
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Destination Address</th>
                                <th>Price</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {delivery?.bookings?.map((booking) => {
                                const {
                                  id,
                                  name,
                                  email,
                                  destination_address,
                                  phone,
                                  status,
                                  product_price,
                                  uuid,
                                } = booking;
                                return (
                                  <tr key={id}>
                                    <td>
                                      {name} <br />
                                      <small className="text-green">
                                        {email}
                                      </small>
                                    </td>
                                    <td>{phone}</td>
                                    <td>{destination_address}</td>
                                    <td>
                                      <CurrencyFormat
                                        value={product_price}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"₦"}
                                        renderText={(value) => <>{value}</>}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        color:
                                          status === "confirmed"
                                            ? "#29CC97"
                                            : status === "processed"
                                            ? "#059669"
                                            : status === "expired"
                                            ? "#DC1515"
                                            : "#FEC400",
                                      }}
                                    >
                                      {status}
                                      {status === "confirmed" && (
                                        <div>
                                          <button
                                            onClick={() =>
                                              activateDelivery(uuid)
                                            }
                                            className="badge bg-success border-0"
                                          >
                                            ready
                                          </button>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <p>You have no bookings</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryDetails;
