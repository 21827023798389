import { useState } from "react";
import "./Sidebar.css";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { accessToken } from "../../helpers/accessToken";
import { useEffect } from "react";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});

  const toggle = () => {
    setOpen(!open);
  };

  // Fetch User Details
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    const uuid = localStorage.getItem("uuid");
    fetch(`${baseURL}/api/v1/users/${uuid}`, options)
      .then((data) => data.json())
      .then((response) => {
        if (response.message === "Unauthenticated.") {
          window.location = `/`;
        }
        setUser(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  // logout 
  const logout = () => {
    if(window.confirm('Are you sure you want to logout?')) {
      localStorage.clear()
    window.location.href = '/'
    }
    return false
  }

  return (
    <div className={`sidebar ${open && "open"}`} onClick={toggle}>
      <div className="logo-details">
        {open && (
          <Link to="/dashboard">
            <img src={logo} alt="logo" width={40} />
          </Link>
        )}
        <i className="bx bx-menu" id="btn"></i>
      </div>
      <ul className="nav-list">
        <li className="bsk">
          <Link to="/dashboard">
            <i className="bx bx-grid-alt"></i>
            <span className="links_name">Dashboard</span>
          </Link>
          <span className="tooltip">Dashboard</span>
        </li>
        <li>
          <Link to="/bulk-delivery">
            <i className="bx bx-package"></i>
            <span className="links_name">Create Store</span>
          </Link>
          <span className="tooltip">Create Store</span>
        </li>
        <li>
          <Link to="/fetch-delivery">
            <i className="bx bx-pie-chart-alt-2"></i>
            <span className="links_name">View Store</span>
          </Link>
          <span className="tooltip">View Store</span>
        </li>
        <li>
          <Link to="/add-account">
            <i className="bx bx-credit-card"></i>
            <span className="links_name">Add Account</span>
          </Link>
          <span className="tooltip">Add Account</span>
        </li>
        <li>
          <Link to="/withdrawal">
            <i className="bx bx-money"></i>
            <span className="links_name">Withdrawal</span>
          </Link>
          <span className="tooltip">Withdrawal</span>
        </li>
        <li className="profile">
          <div className="profile-details">
            <img
              src="https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png"
              alt="profileImg"
            />
            <div className="name_job">
              <div className="name">{user.full_name}</div>
              <div className="job">Online</div>
            </div>
          </div>
          <i
            className="bx bx-log-out text-white"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Logout"
            id="log_out"
            onClick={() => logout()}
          ></i>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
