import Login from "./components/Login";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Identification from "./components/identification/Identification";
import Dashboard from "./components/dashboard/Dashboard";
import User from "./components/dashboard/User";
import BulkDelivery from "./components/bulk-delivery/BulkDelivery";
import CreateProduct from "./components/product/CreateProduct";
import FetchDelivery from "./components/bulk-delivery/FetchDelivery";
import Verify from "./components/identification/Verify";
import Location from "./utils/searchLoacation";
import DeliveryDetails from "./components/bulk-delivery/DeliveryDetails";
import Booking from "./components/booking/Booking";
import Withdrawal from "./components/withdrawal/Withdrawal";
import Map from "./components/bulk-delivery/Map";
import AddAccount from "./components/card/AddAccount";
import Cart from "./components/Cart";
import { ShopProvider } from "./context/shopContext";
import EditProducts from "./components/product/EditProducts";
import Home from "./components/home/Home";

const App = () => {
  return (
    <ShopProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/submit-identification" element={<Identification />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/bulk-delivery" element={<BulkDelivery />} />
        <Route path="/:name/:ref/:type" element={<Booking />} />
        <Route path="/fetch-delivery" element={<FetchDelivery />} />
        <Route path="/bulk-deliveries/:id" element={<DeliveryDetails />} />
        <Route path="/create-product" element={<CreateProduct />} />
        <Route path="/user" element={<User />} />
        <Route path="/location" element={<Location />} />
        {/* <Route path="/:name/:ref/:type/:id" element={<CreateBooking />} /> */}
        <Route path="/add-account" element={<AddAccount />} />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/map" element={<Map />} />
        <Route path="/cart/:id" element={<Cart />} />
        <Route
          path="/:uid/bulk-deliveries/:uuid/products/:id"
          element={<EditProducts />}
        />
      </Routes>
    </ShopProvider>
  );
};

export default App;
