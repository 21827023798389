import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { toast, ToastContainer } from 'react-toastify'
import Sidebar from '../dashboard/Sidebar'

const CreateProduct = () => {
  const [image, setImage] = useState([])
  const [token, setToken] = useState('')
  const [disableButton, setDisbaleButton] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false)
  const [details, setDetails] = useState({
    sizeId: '',
    label: '',
    description: '',
    unit: '',
    unitPrice: '',
    isFragile: '',
    note: '',
  })

  // Fetch access token
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    const baseURL = process.env.REACT_APP_BASEURL
    fetch(`${baseURL}/api/v1/fetch-access-token`, options)
      .then(data => data.json())
      .then(response => {
        setToken(response.data.access_token)
      })
      .catch(error => {
        console.log(error.message)
      })
  }, [])

  console.log(token, 'new token')

  // Upload image to google drive
  const uploadImage = async () => {
    let formData = new FormData();
    formData.append("image", image)
    const options = {
      method: "POST",
      headers: new Headers({ Authorization: `Bearer ${token}` }),
      body: formData,
    }
    const data = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', options);
    await data.json()
    .then(response => {
      console.log(response)
    })
    .catch(error => console.log(error, 'error message'))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('button clicked');
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        size_id: details.sizeId,
        label: details.label,
        description: details.description,
        unit: details.unit,
        unit_price: details.unitPrice,
        is_fragile: details.isFragile,
        images: image,
        note: details.note
      }),
    }
    const baseURL = process.env.REACT_APP_BASEURL
    const uuid = localStorage.getItem("uuid")
    fetch(`${baseURL}/api/v1users/${uuid}/bulk-deliveries/60ed9220-59b9-11ed-8fe8-6b7aa4de64c9/products`, options)
      .then(data => data.json())
      .then(response => {
        setDisbaleButton(false)
        setButtonLoad(false)
        toast(response.message)
      })
      .catch(error => {
        console.log(error.message)
        setDisbaleButton(false)
        setButtonLoad(false)
      })
  }

  return (
    <>
      <Sidebar />
      <div className="container my-5">
        <ToastContainer />
        <div className="row d-flex justify-content-center">
          <div className="col-md-10 main-card">
            <div className="card">
              <div className="card-body">
                <h5 className='text-green'>Create Product</h5>
                <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-6 my-4">
                  <label htmlFor="size">Size</label>
                <select className="form-select form-control shadow-none"
                required
                >
                  <option defaultValue>Select Size</option>
                  <option value="yes">small</option>
                  <option value="no">large</option>
                </select>
                </div>
                <div className="col-md-6 my-4">
                  <label htmlFor="label">Label</label>
                  <input type="text"
                  value={details.label}
                  onChange={e => setDetails({
                    ...details,
                    label: e.target.value,
                  })} 
                  className='form-control shadow-none' 
                  placeholder='Label'
                  required
                  />
                </div>
                </div>
                <div className="col-md-6 my-4">
                  <label htmlFor="description">Description</label>
                  <textarea 
                  value={details.description}
                  onChange={e => setDetails({
                    ...details,
                    description: e.target.value,
                  })}
                  className='form-control shadow-none' 
                  placeholder='Description' 
                  required 
                  />
                </div>
                <div className="row">
                <div className="col-md-4 my-4">
                  <label htmlFor="unit">Unit</label>
                  <input type="text"
                  value={details.unit}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (
                      e.target.value === "" ||
                      re.test(e.target.value)
                    ) {
                      setDetails({
                        ...details,
                        unit: e.target.value,
                      })
                    }
                  }}
                  className='form-control shadow-none' 
                  placeholder='unit'
                  required
                  />
                </div>
                <div className="col-md-4 my-4">
                  <label htmlFor="unit-price">Unit Price</label>
                  <CurrencyInput
                    name="input-name"
                    placeholder="unit price"
                    className='form-control shadow-none' 
                    required
                    defaultValue={details.unitPrice}
                    decimalsLimit={2}
                    onValueChange={(value, name) => console.log(value, name)}
                  />
                </div>
                <div className="col-md-4 my-4">
                  <label htmlFor="is-fragile">Fragile</label>
                <select className="form-select form-control shadow-none"
                required
                >
                  <option defaultValue>Select Type</option>
                  <option value="1">yes</option>
                  <option value="0">no</option>
                </select>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6 my-4">
                  <input type="file" 
                  className='form-control shadow-none'
                  name='file'
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  onChange={e => {
                    setImage(e.target.files[0])
                    uploadImage()
                  }}
                   />
                </div>
                <div className="col-md-6 my-4">
                  <textarea 
                  value={details.note}
                  onChange={e => setDetails({
                    ...details,
                    note: e.target.value,
                  })}
                  className='form-control shadow-none' 
                  placeholder='Note' 
                  required 
                  />
                </div>
                </div>
                <button className='submit-btn' type='submit' disabled={disableButton}>
                    {buttonLoad ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className="spinner-border text-white"
                          role="status"
                          style={{ width: '25px', height: '25px', fontSize: '10px'}}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateProduct