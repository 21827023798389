import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { accessToken } from "../../helpers/accessToken";
import Sidebar from "../dashboard/Sidebar";

const EditProducts = () => {
  const [loading, setLoading] = useState(true);
  const [disableButton, setDisbaleButton] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [product, setProduct] = useState({
    label: "",
    description: "",
    unit: "",
    unit_price: "",
    isFragile: "",
    note: "",
  });

  const { uid, uuid, id } = useParams();
  // Fetch product details
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(
      `${baseURL}/api/v1/users/${uid}/bulk-deliveries/${uuid}/products/${id}`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        console.log(response.data);
        setProduct(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(error.message);
        setLoading(false);
      });
  }, [uid, uuid, id]);

  const handleSubmit = (e) => {
    setButtonLoad(true);
    setDisbaleButton(true);
    e.preventDefault();
    let unit_price = product.unit_price.replace(",", "").replace(".00", "");
    const options = {
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        size_id: product.size.uuid.toString(),
        label: product.label,
        description: product.description,
        unit: product.unit,
        unit_price,
        is_fragile: product.is_fragile,
        note: product.note,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    const uuid = localStorage.getItem("uuid");
    fetch(
      `${baseURL}/api/v1/users/${uid}/bulk-deliveries/${uuid}/products/${id}`,
      options
    )
      .then((data) => data.json())
      .then((response) => {
        setButtonLoad(false);
        toast.success(response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        console.log(error.message);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };

  return (
    <>
      <Sidebar />
      <div className="container my-5">
        <ToastContainer />
        <div className="row d-flex justify-content-center">
          <div className="col-md-10 main-card">
            <div className="card">
              <div className="card-body">
                <h5 className="text-green">Edit Product</h5>
                {loading ? (
                  <div className="text-center">
                    <div className="spinner-border text-green" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <img
                        src={product?.images}
                        alt={product?.label}
                        className="img-fluid rounded my-3"
                        style={{ width: "400px" }}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6 my-4">
                        <label htmlFor="label">Label</label>
                        <input
                          type="text"
                          value={product?.label}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              label: e.target.value,
                            })
                          }
                          className="form-control shadow-none"
                          placeholder="Label"
                          required
                        />
                      </div>
                      <div className="col-md-6 my-4">
                        <label htmlFor="description">Description</label>
                        <input
                          value={product?.description}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              description: e.target.value,
                            })
                          }
                          className="form-control shadow-none"
                          placeholder="Description"
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2 my-4">
                        <label htmlFor="unit">Unit</label>
                        <input
                          type="text"
                          value={product?.unit}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              setProduct({
                                ...product,
                                unit: e.target.value,
                              });
                            }
                          }}
                          className="form-control shadow-none"
                          placeholder="unit"
                          required
                        />
                      </div>
                      <div className="col-md-4 my-4">
                        <label htmlFor="unit-price">Unit Price</label>
                        <input
                          type="text"
                          className="form-control shadow-none"
                          value={product?.unit_price}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              unit_price: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-6 my-4">
                        <label htmlFor="note">Note</label>
                        <input
                          value={product?.note}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              note: e.target.value,
                            })
                          }
                          className="form-control shadow-none"
                          placeholder="Note"
                          required
                        />
                      </div>
                    </div>
                    <div className="row"></div>
                    <button
                      className="submit-btn border-0"
                      type="submit"
                      disabled={disableButton}
                    >
                      {buttonLoad ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border text-white"
                            role="status"
                            style={{
                              width: "25px",
                              height: "25px",
                              fontSize: "10px",
                            }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        "Update"
                      )}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProducts;
