import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { accessToken } from "../../helpers/accessToken";
import Sidebar from "./Sidebar";
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  // Fetch User Details
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    const uuid = localStorage.getItem("uuid");
    fetch(`${baseURL}/api/v1/users/${uuid}`, options)
      .then((data) => data.json())
      .then((response) => {
        if (response.message === "Unauthenticated.") {
          window.location = `/`;
        }
        setUser(response.data);
        console.log(response.data)
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Sidebar />
      {loading ? (
        <div className="container my-5">
          <div className="row d-flex justify-content-center">
            <div className="col-md-10">
              <div className="card">
                <div className="card-body text-center">
                  <div className="spinner-border text-green" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container my-5">
          <div className="row d-flex justify-content-end">
            <div className="col-md-10 main-card">
              <div className="row reverse">
                <div className="col-md-8 my-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="trans">
                        <h4 className="fw-bold">
                          Welcome back,
                          <span
                            style={{ color: "rgb(255, 101, 1)" }}
                          >
                            {user?.full_name}
                          </span>
                        </h4>
                        <p>view your deliveries here</p>
                        <Link to={`/fetch-delivery`} className="submit-btn">
                          Deliveries
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-4">
                  <div className="card" style={{ minHeight: "228px" }}>
                    <div className="card-body">
                      <h6>Wallet</h6>
                      <div className="wallet-card">
                        <small>Balance</small>
                        <h3 className="fw-bold">₦ {user?.wallet?.balance}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-10 main-card">
              <div className="row">
                <div className="col-md-3 text-center mb-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`/bulk-delivery`}>
                        <div>
                          <i className="bx bx-package first" style={{ background: '#99ffcc'}}></i>
                        </div>{" "}
                        <p className="text-secondary mt-2">Create store</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 text-center mb-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`/fetch-delivery`}>
                        <div>
                          <i className="bx bx-pie-chart-alt-2 first" style={{background: '#ffaa69'}}></i>
                        </div>{" "}
                        <p className="text-secondary mt-2">View store</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 text-center mb-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`/add-account`}>
                        <div>
                          <i className="bx bx-credit-card first" style={{ background: '#4f5bff'}}></i>
                        </div>{" "}
                        <p className="text-secondary mt-2">Add Account</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 text-center mb-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`/withdrawal`}>
                        <div>
                          <i className="bx bx-money first" style={{ background: '#ff4ffc'}}></i>
                        </div>{" "}
                        <p className="text-secondary mt-2">Withdraw</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
