import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { accessToken, uuid } from "../../helpers/accessToken";
import Sidebar from "../dashboard/Sidebar";
import Geocode from "react-geocode";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { FaMapPin, FaUpload } from "react-icons/fa";

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

let center = {
  lat: 6.4307739,
  lng: 3.4301063,
};

const BulkDelivery = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imageId, setImageId] = useState("");
  const [token, setToken] = useState("");
  const [disableButton, setDisbaleButton] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [expire, setExpire] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [available, setAvailable] = useState();
  const [markers, setMarkers] = useState([]);

  // Fetch access token
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/fetch-access-token`, options)
      .then((data) => data.json())
      .then((response) => {
        setToken(response.data.access_token);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  // Upload image to google drive
  const uploadImage = (image) => {
    setSpinner(true);
    setDisbaleButton(true);
    const file = image;
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (f) => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      );
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      );
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      };
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then((response) => response.json())
        .then((response) => {
          setImageId(response.id);
          setSpinner(false);
          setDisbaleButton(false);
        })
        .catch((error) => console.log(error, "error message"));
    };
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    console.log(
      results[0].address_components.map((address) => address.long_name)
    );
    console.log(results[0].address_components.map((address) => address.types));
    console.log(
      results[0].address_components.map((address) => address.long_name[0])
    );
    const ll = await getLatLng(results[0]);
    console.log(ll, "latiii");
    console.log(ll.lat, ll.lng);
    console.log(
      results[0].formatted_address.split(",")[
        results[0].formatted_address.split(",").length - 2
      ]
    );
    // setState(
    //   results[0].formatted_address.split(",")[
    //     results[0].formatted_address.split(",").length - 2
    //   ]
    // );
    setAddress(value);
    center = {
      lat: ll.lat,
      lng: ll.lng,
    };
    setCoordinates(`${ll.lat}, ${ll.lng}`);
  };

  const checkLocation = () => {
    console.log(state);
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        state,
        coordinates,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then((data) => data.json())
      .then((response) => {
        if (response.status !== "success") {
          console.log(response.status);
          setAvailable(response.message);
        }
        // else {
        //   console.log(response.message);
        //   console.log(response.status);
        //   setAvailable(response.message);
        // }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // handle map click
  const handleEvent = (event) => {
    let latCord = `${event.latLng.lat()}`;
    let lngCord = `${event.latLng.lng()}`;
    console.log(latCord);
    console.log(lngCord);

    // Set Marker

    setMarkers((current) => [
      ...current,
      {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
    ]);

    // Geocode
    Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_MAP_API}`);

    // set response language. Defaults to english.
    Geocode.setLanguage("en");
    // Get address from latitude & longitude.
    Geocode.fromLatLng(`${latCord}`, `${lngCord}`).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
        setAddress(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisbaleButton(true);
    setButtonLoad(true);
    checkLocation();
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        name,
        description,
        image: imageId,
        pickup_address: address,
        pickup_coordinates: coordinates,
        pickup_state: state,
        expires_at: expire,
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/users/${uuid}/bulk-deliveries`, options)
      .then((data) => data.json())
      .then((response) => {
        setButtonLoad(false);
        toast(response.message);
        console.log(response.status);
        setDisbaleButton(false);
        setButtonLoad(false);
        if (response.status === "success") {
          console.log(response.data.uuid, 'response data')
          setTimeout(() => {
            const url = window.location.origin;
            window.location = `${url}/bulk-deliveries/${response.data.uuid}`;
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setDisbaleButton(false);
        setButtonLoad(false);
      });
  };

  // Google map

  // -------------------------------------------------------------------

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ["places"],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ null);

  // if (!isLoaded) {
  //   return "loading....";
  // }

  // -----------------------------------------------------------------

  return (
    <>
      <Sidebar />
      <div className="container my-5">
        <ToastContainer />
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 main-card">
            <div className="card">
              <div className="card-body">
                <h5 className="text-green">Create Store</h5>
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12 my-4">
                    <label htmlFor="delivery-name">Name of Store</label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control shadow-none"
                      placeholder="e.g  Funky's Collections"
                      required
                    />
                  </div>
                  <div className="col-md-12 my-4">
                    <label htmlFor="description">Description</label>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="form-control shadow-none"
                      placeholder="e.g Classy Affordable Bags"
                      required
                    />
                  </div>
                  <div className="col-md-12 my-4">
                    <label htmlFor="image" className="text-green">
                    customize your store with an image
                    </label>
                    <input
                      type="file"
                      name="file"
                      className="form-control shadow-none"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                        uploadImage(e.target.files[0]);
                      }}
                      required
                    />
                    {spinner && (
                      <small className="text-green">
                        <FaUpload /> uploading...
                      </small>
                    )}
                  </div>
                  <div className="col-md-12 my-4">
                    <label htmlFor="state">Pickup State</label>
                    <select value={state}
                      className="form-control shadow-none"
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                      required>
                      <option>--select state--</option>
                      <option value="lagos">Lagos</option>
                      <option value="ondo">Ondo</option>
                      <option value="ogun">Ogun</option>
                      <option value="oyo">Oyo</option>
                    </select>
                  </div>
                  <div className="col-md-12 my-4">
                    <label htmlFor="pickup">Pickup Address(Address you'll be shipping the item from)</label>
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Enter Pickup Address",
                              className: "form-control shadow-none",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  key={suggestion.index}
                                >
                                  <span onSelect={checkLocation}>
                                    <FaMapPin className="text-green mx-1" />{" "}
                                    {suggestion.description}
                                  </span>
                                  <hr />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div
                        className="map"
                        style={{ height: "50vh", width: "100vw" }}
                      >
                        <GoogleMap
                          center={center}
                          zoom={17}
                          mapContainerStyle={{ width: "100%", height: "100%" }}
                          onLoad={(map) => setMap(map)}
                          onClick={(e) => handleEvent(e)}
                        >
                          <Marker position={center} />
                          <Marker position={center} />
                          {markers.map((marker) => (
                            <Marker
                              position={{
                                lat: marker.lat,
                                lng: marker.lng,
                              }}
                            />
                          ))}
                        </GoogleMap>
                      </div>
                    </div>
                  </div>
                  {/* <button className="submit-btn badge" onClick={checkLocation}>
                    check availability
                  </button> */}
                  <br />
                  <small className="text-orange">{available}</small>
                  <div className="col-md-12 my-4">
                    <label htmlFor="expires">Expiry date (optional)</label>
                    <input
                      type="datetime-local"
                      className="form-control shadow-none"
                      value={expire}
                      onChange={(e) => {
                        setExpire(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    className="submit-btn px-5"
                    type="submit"
                    disabled={disableButton}
                  >
                    {buttonLoad ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className="spinner-border text-white"
                          role="status"
                          style={{
                            width: "25px",
                            height: "25px",
                            fontSize: "10px",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkDelivery;
