import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { ShopContext } from "../../context/shopContext";
import Nav from "../../utils/Nav";

export const reference = window.location.pathname.split("/")[2];

// console.log(window.location.pathname);

const Booking = () => {
  const [loading, setLoading] = useState(true);
  const [delivery, setDelivery] = useState({});

  const { addToCart, cartItems, removeFromCart } = useContext(ShopContext);

  // Fetch Delviery
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    };
    const baseURL = process.env.REACT_APP_BASEURL;
    fetch(`${baseURL}/api/v1/bulk-deliveries/${reference}`, options)
      .then((data) => data.json())
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        setDelivery(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <>
      <Nav />
      <div className="container my-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                {/* <h4 className="text-green">Store Details</h4> */}
                {loading ? (
                  <div className="container">
                    <div className="row my-2">
                      <div className="col-md-6">
                        <div className="col mb-3 skeleton skeleton-img rounded"></div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-2 skeleton skeleton-p"></div>
                        <div className="col-md-3 skeleton skeleton-head mb-3"></div>
                        <div className="col-md-4 skeleton skeleton-p"></div>
                        <div className="col-md-3 skeleton skeleton-p mb-3"></div>
                        <div className="col-md-4 skeleton skeleton-p"></div>
                        <div className="col-md-8  skeleton skeleton-p mb-3"></div>
                        <div className="col-md-5 skeleton skeleton-p"></div>
                        <div className="col-md-10 skeleton skeleton-p mb-3"></div>
                        <div className="col-md-3 skeleton skeleton-p"></div>
                        <div className="col-md-4 skeleton skeleton-p mb-2"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="container">
                      <div className="row">
                        <div
                          className="banner"
                          style={{
                            background: `linear-gradient(0deg, rgba(0, 0, 150, 0.3), rgba(20, 0, 150, 0.3)), url(${delivery?.image})`,
                            height: "200px",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            borderRadius: "5px",
                          }}
                        ></div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6 mt-4">
                              <h4>{delivery?.name}</h4>
                              <p>{delivery?.description}</p>
                              <p
                                style={{
                                  color:
                                    delivery?.status === "active"
                                      ? "#29CC97"
                                      : delivery?.status === "expired"
                                      ? "#DC1515"
                                      : "#FEC400",
                                }}
                              >
                                {delivery?.status}
                              </p>
                              {delivery?.expires_at ? (
                                <>
                                  <small className="text-green">
                                    Expires at
                                  </small>
                                  <p>{delivery?.expires_at}</p>
                                </>
                              ) : null}
                            </div>
                            <div className="col-md-6 mt-3">
                              <h6>MERCHANT DETAILS</h6>
                              <h5>{delivery?.user?.name}</h5>
                              <p className="m-0 text-secondary">
                                {delivery?.user?.email}
                              </p>
                              <p>{delivery?.user?.phone}</p>
                            </div>
                            <div className="col-md-6 pb-4">
                              {/* <img
                                src={delivery?.image}
                                alt={delivery?.name}
                                className="img-fluid rounded mb-3"
                                width={265}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="text-green">Products</h5>

                    {delivery?.products?.length ? (
                      delivery?.products?.map((product) => {
                        const {
                          id,
                          description,
                          images,
                          is_availalble,
                          label,
                          reference,
                          unit_price,
                          unit,
                        } = product;
                        return (
                          <div key={id}>
                            <div className="row py-2">
                              <div className="col-md-6 py-3">
                                <img
                                  src={images}
                                  alt={description}
                                  className="img-fluid rounded mb-3"
                                  width={200}
                                />
                                <p className="mt-2 mb-0">
                                  <strong>{label}</strong>
                                </p>
                                <small>{description}</small> <br />
                                <small>{reference}</small>
                              </div>
                              <div className="col-md-6 py-3">
                                <p className="text-orange">₦ {unit_price}</p>
                                <p>{is_availalble}</p>
                                <p>{label} per {unit}</p>
                                <div>
                                  <div className="">
                                    <div>
                                      {cartItems[id] > 0 && (
                                        <>
                                          <button
                                            className="qty-btn"
                                            onClick={() => removeFromCart(id)}
                                          >
                                            -
                                          </button>
                                          <span className="mx-3">
                                            {cartItems[id]}
                                          </span>
                                          <button
                                            className="qty-btn"
                                            onClick={() => {
                                              addToCart(id);
                                            }}
                                          >
                                            +
                                          </button>
                                        </>
                                      )}
                                      <div>
                                        {cartItems[id] === 0 && (
                                          <button
                                            onClick={() => {
                                              addToCart(id);
                                            }}
                                            className="buy-btn"
                                          >
                                            Add To Cart
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p>No Products have been created</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
